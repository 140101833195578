<mat-optgroup
	#trigger="matMenuTrigger"
	*ngFor="let item of groups"
	class="w-[160px] block hover:bg-primary-100 dark:hover:bg-primary-300"
	[label]="item.label"
	(click)="
		toggleExpand(item.label);
		switchTrigger(trigger, item.label); 
		$event.stopPropagation()
	"
	[matMenuTriggerFor]="optionMenu"
	(menuOpened)="manageOptions()"
	(clickOutside)="switchTrigger(trigger, item.label, true)">
	<button mat-button class="absolute right-0">
		<mat-icon *ngIf="isExpanded()[item.label]">arrow_drop_down</mat-icon>
		<mat-icon *ngIf="!isExpanded()[item.label]">arrow_right</mat-icon>
	</button>

	<mat-menu
		#optionMenu="matMenu"
		[hasBackdrop]="false"
		class="absolute -top-12 left-[160px] cursor-pointer dark:!bg-primary-400"
		(closed)="resetExpand(item.label)">
		<green-option-group
			*ngIf="item.groups && item.groups.length; else options"
			[groupConfig]="item.groups"
			(selectedOptions)="selectedOptions.emit($event)"
			[multiple]="multiple"
			[selected]="selectedElements()"
			[clickOutside]="hasClickedOutside()"
			[canExpand]="canExpandToggle">
		</green-option-group>

		<ng-template #options>
			<mat-option
				#option
				class="option mr-1 dark:!text-white"
				name="type"
				*ngFor="let element of item.options; let i = index"
				(click)="selectOption(option, element, item.label, $event)"
				[value]="element.name">
				<green-truncated-text
					class="whitespace-nowrap"
					[value]="element.name"
					[maxLength]="option.selected ? 13 : 25"/>
			</mat-option>
		</ng-template>
	</mat-menu>
</mat-optgroup>
